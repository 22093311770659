<template>
    <section class="pqr-nueva-resolucion mt-4 ml-4">
        <div class="row mb-4">
            <div class="col-12 col-lg-4">
                 <div class="row mt-4">
                    <div class="col-12">
                        <el-breadcrumb separator-class="el-icon-arrow-right" class="f-300">
                            <el-breadcrumb-item :to="{ name: 'pqr.solicitudes' }">PQRs</el-breadcrumb-item>
                            <el-breadcrumb-item>Nueva resolucion</el-breadcrumb-item>
                        </el-breadcrumb>
                    </div>
                </div>
                <div class="row mt-4">
                    <p class="col-12 f-20 f-500 mb-3">Nueva Resolución</p>
                    <ValidationProvider tag="div" class="col-12 col-sm-8 mb-3">
                        <p class="f-12 pl-2 label-inputs">Título</p>
                        <el-input placeholder="Nombre" v-model="inputNombreResolucion" size="small" class="w-100" />
                    </ValidationProvider>
                    <ValidationProvider tag="div" class="col-12 mb-3">
                        <div class="d-middle">
                            <p class="f-15">Activar</p>
                            <el-switch v-model="activarResolucion" active-color="#00D9B5" inactive-color="#E6E6E6" class="mx-2" />
                        </div>
                    </ValidationProvider>
                    <div class="col-auto">
                        <span class="bg-verde-claro rounded-pill f-12 px-2 py-1">
                            15 PQRs Vigentes
                        </span>
                    </div>
                    <div class="col-auto">
                        <span class="bg-amarillo1 rounded-pill f-12 px-2 py-1">
                            154 PQRs Totales
                        </span>
                    </div>
                    <div class="col-12 mt-4">
                        <button class="btn btn-general h-32px f-14 f-300 px-4" @click="guardarResolucion">Guardar</button>
                    </div>
                </div>
            </div>
            <div class="col col-lg-4">
                <div class="row mx-0">
                    <div class="col-auto">
                        <p class="f-20 f-500">Comentarios</p>
                    </div>
                    <div class="col-auto ml-auto">
                       <div class="d-middle">
                            <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="rounded-pill py-2 border-0 shadow-sm">
                                <div slot="content" class="text-86">
                                    Añadir imagen
                                </div>
                                <div class="mas-opciones d-middle-center br-4 f-15 cr-pointer" @click="agregarComentario">
                                    <i class="icon-add-img" />
                                </div>
                            </el-tooltip>
                            <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="rounded-pill py-2 border-0 shadow-sm">
                                <div slot="content" class="text-86">
                                    Añadir comentario
                                </div>
                                <div class="mas-opciones d-middle-center br-4 f-15 cr-pointer ml-2" @click="agregarComentario">
                                    <i class="icon-message-reply-outline" />
                                </div>
                            </el-tooltip>
                       </div>
                    </div>
                </div>
                <div v-for="(data,i) in 2" :key="i" class="row mx-0 my-3">
                    <div class="col-12">
                        <div class="set-comentarios br-4 p-2 mb-3 cr-pointer" @click="agregarComentario">
                            <p class="f-15 lh-18">Disculpe las molestias, tan pronto se conozca la falla se informará y se solucionará</p>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div v-for="data in listadoAdjuntos" :key="data.id" class="col-auto pr-1 mb-3 cr-pointer">
                                <div class="contenedor-img">
                                    <img :src="data.src" alt="" class="w-100 h-100 br-2">
                                    <div class="opciones text-white">
                                        <i class="icon-search mr-2" @click="verImagenes" />
                                        <i class="icon-delete-outline" @click="eliminarImagen" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-ver-img ref="abrirModalVerImagenes" />
        <modal-lateral ref="abrirModalAgregarComentario" titulo="Añadir un comentario">
            <ValidationObserver tag="div" class="row mx-0">
                    <div class="col-12">
                        <ValidationProvider tag="div">
                            <el-input placeholder="Comentario" v-model="inputComentario" type="textarea"  :autosize="{ minRows: 5}" />
                        </ValidationProvider>
                    </div>
                     <div class="col-12 text-right">
                        <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="rounded-pill py-2 border-0 shadow-sm">
                            <div slot="content" class="text-86">
                                Eliminar comentario
                            </div>
                            <div class="mas-opciones d-middle-center br-4 f-15 cr-pointer ml-auto mt-2" @click="eliminarComentario">
                                <i class="icon-delete-outline" />
                            </div>
                        </el-tooltip>
                     </div>
                    <div class="botones">
                         <button type="button" class="btn mx-2 btn-cerrar f-300 f-14 h-32px px-4 br-4 border bg-db text-86" @click="cerrarComentario"> Cerrar </button>
                         <button type="button" class="btn mx-2 btn-aceptar f-300 f-14 h-32px text-white br-4 bg-db text-86" @click="enviarComentario"> Enviar </button>
                    </div>
            </ValidationObserver>
        </modal-lateral>
        <modal-eliminar ref="modalEliminarComentario" titulo="Eliminar comentario" mensaje="¿Desea eliminar este comentario?" tamano="modal-md" class="f-15 text-center" />
        <modal-eliminar ref="modalEliminarImagen" titulo="Eliminar imagen" mensaje="¿Desea eliminar esta imagen?" tamano="modal-md" class="f-15 text-center" />
    </section>
</template>
<script>
export default {
    components:{
        modalVerImg: () => import ('./partials/modalVerImg'),
    },
    data(){
        return{
            inputSearch:'',
            inputNombreResolucion:'',
            inputComentario:'',
            activarResolucion:true,
            listadoAdjuntos:[
                {
                    id: 0,
                    src: '/img/pruebas/trasteo0.png',
                },
                {
                    id: 1,
                    src: '/img/pruebas/trasteo1.png',
                },
                {
                    id: 2,
                    src: '/img/pruebas/trasteo2.png',
                },
                {
                    id: 3,
                    src: '/img/pruebas/trasteo3.png',
                },
                {
                    id: 4,
                    src: '/img/pruebas/trasteo3.png',
                },
                {
                    id: 5,
                    src: '/img/pruebas/trasteo2.png',
                },
            ],
            listadoComentarios:[
                {
                    id: 0,
                    comentario: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus perspiciatis obcaecati perferendis iusto porro tenetur, nulla eaque officiis recusandae accusamus.',
                    fecha: '11 Ago. 2020 - 10:23 a.m.',
                    vinculado: 1
                },
                {
                    id: 1,
                    comentario: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus perspiciatis obcaecati perferendis iusto porro tenetur, nulla eaque officiis recusandae accusamus.',
                    fecha: '11 Ago. 2020 - 10:23 a.m.',
                    vinculado: 0
                },
                {
                    id: 2,
                    comentario: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus perspiciatis obcaecati perferendis iusto porro tenetur, nulla eaque officiis recusandae accusamus.',
                    fecha: '11 Ago. 2020 - 10:23 a.m.',
                    vinculado: 0
                },
                {
                    id: 3,
                    comentario: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus perspiciatis obcaecati perferendis iusto porro tenetur, nulla eaque officiis recusandae accusamus.',
                    fecha: '11 Ago. 2020 - 10:23 a.m.',
                    vinculado: 1
                },
                {
                    id: 4,
                    comentario: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus perspiciatis obcaecati perferendis iusto porro tenetur, nulla eaque officiis recusandae accusamus.',
                    fecha: '11 Ago. 2020 - 10:23 a.m.',
                    vinculado: 0
                },
                {
                    id: 5,
                    comentario: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus perspiciatis obcaecati perferendis iusto porro tenetur, nulla eaque officiis recusandae accusamus.',
                    fecha: '11 Ago. 2020 - 10:23 a.m.',
                    vinculado: 0
                },
                {
                    id: 6,
                    comentario: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus perspiciatis obcaecati perferendis iusto porro tenetur, nulla eaque officiis recusandae accusamus.',
                    fecha: '11 Ago. 2020 - 10:23 a.m.',
                    vinculado: 0
                },
                {
                    id: 7,
                    comentario: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus perspiciatis obcaecati perferendis iusto porro tenetur, nulla eaque officiis recusandae accusamus.',
                    fecha: '11 Ago. 2020 - 10:23 a.m.',
                    vinculado: 0
                },
            ],
        }
    },
    methods:{
        agregarComentario(){
            this.$refs.abrirModalAgregarComentario.toggle()
        },
        cerrarComentario(){
            this.$refs.abrirModalAgregarComentario.toggle()
        },
        enviarComentario(){
            this.$refs.abrirModalAgregarComentario.toggle()
        },
        eliminarComentario(){
            this.$refs.modalEliminarComentario.toggle()
        },
        verImagenes(){
            this.$refs.abrirModalVerImagenes.toggle()
        },
        eliminarImagen(){
            this.$refs.modalEliminarImagen.toggle()
        },
        guardarResolucion(){
            this.$router.push({ name: 'pqr.resoluciones' })
        },
    }
}
</script>
<style lang="scss" scoped>
.pqr-nueva-resolucion{
    .set-comentarios{
        background: var(--color-f5);
        border: 1px solid var(--color-db);
    }
    .contenedor-img{
        width: 112px;
        height: 74px;
        position: relative;
        .opciones{
            position: absolute;
            bottom: 7px;
            left: 36px;
            transition: .35s;
            cursor: pointer;
            opacity: 0;
        }
        &:hover{
            .opciones, .icon-me_interesa{
                transition: .45s;
                opacity: 1;
            }
        }
    }
    .bg-amarillo1{
        background: #FFD54F;
    }
}
</style>